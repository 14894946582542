@import "styles/mixins";

.ageGate {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.5);
  // background:var(--clr-neutral-900);
  text-wrap: balance;
  overflow-y: auto;
}

.content {
  width: 450px;
  max-width: 90%;
  background: var(--clr-neutral-150);
  position: absolute;
  top: 10px; /* Adjust this value if you want more spacing from the top */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--border-radius);
  overflow-y:auto;
  @include mobile() {
    padding: 1rem;
    max-width: 100%;
    width: 100%;
    top:0px;
    border-radius: 0 0 15px 15px;
  }
  
  input {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    accent-color: var(--clr-secondary-300);
  }
  
  button {
    width: 100%;
  }
  
  button:disabled {
    opacity: 0.5;
    background: var(--clr-neutral-650);
    border-color: var(--clr-neutral-650);
    cursor: not-allowed;
    &:hover {
      background: var(--clr-neutral-400);
    }
  }
}


.buttons {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0.5rem 0rem 1rem 0rem;
  & {
    width: 100%;
  }
}

.tabs {
  display: flex;
  width: 100%;
}

.tab {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  align-items: center;
  justify-content: center;
  padding: 0.8em;
  border: 1px solid transparent;
  color: var(--clr-neutral-800);
  border: var(--locationpopup-tabs-border);
  // opacity:0.5;
  svg {
    max-height: 50px;
  }
  &__content {
    display: none;
    width: 100%;
  }
  &:first-of-type {
    border-radius: var(--border-radius) 0px 0px var(--border-radius);
  }
  &:nth-of-type(2) {
    border-radius: 0px var(--border-radius) var(--border-radius) 0px;
  }
  @include mobile() {
    padding: 5px;
  }
}

.active {
  background: var(--clr-neutral-100);
  border: 1px solid var(--clr-primary-100);
  opacity: 1;
  &__content {
    display: block;
  }
  span {
    font-weight: 500;
  }
}

.orderTypeSelection {
  width: 100%;
  border:1px solid transparent;
  border-radius:var(--border-radius);
  padding:5px;
}
.orderTypeSelectionWarning {
  border-color:var(--clr-danger);
  border-style: dashed;
}

.pickupLocations {
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.delivery_locations {
  display: flex;
  flex-direction: column;
  gap: 1em;
  a {
    text-align: center;
    width: fit-content;
  }
  input {
    padding: 0.8em;
    border: var(--locationpopup-tabs-border);
  }
  .delivery_input {
    display: flex;
    flex-direction: column;
    gap: 0.3em;
    a {
      align-self: flex-end;
    }
  }
}


.store {
  position: relative;
  display: flex;
  align-items: baseline;
  padding: 1rem 1.25rem;
  gap: 1rem;
  cursor: pointer;
  transition: border-color 0.3s;
  border-radius:15px;
  border: 1px solid var(--clr-neutral-300); 
  @include mobile() {
    padding: 1rem 1rem;
  }
}

.storeLeft {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  min-width: 150px;
  @include mobile() {
    min-width: 120px;
  }
}

.storeRight {
  flex-grow: 1;
}

.storeTitle {
  font-weight: 600;
  @include mobile() {
    font-size:0.875rem;
  }

}

.selected {
  border: 2px solid var(--clr-accent-300); 
  background:white;
}

.warningText {
  color: var(--clr-danger);
}

.hours {
  font-size: 0.875rem;
  color: var(--clr-neutral-700);
}

.skeleton {
  gap: 0.5rem;
  width: 100%;
  display: flex;
  &Left {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    min-width: 150px;
    > div:first-of-type{
      margin-bottom:5px;
    }
    > div:nth-of-type(2){
      max-width:80%;
      margin-bottom:2px;
    }
  }
  &Right {
    flex-grow: 1;
  }
}