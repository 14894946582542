@import "styles/mixins";

.popup {
  position: absolute;
  width: 500px;
  top: 60px;
  right: 0;
  z-index: 500;
  @include tablet() {
    top:0px;
    width: 100%;
  }
  &__title {
    color: var(--clr-primary-300);
    font-weight: 600;
    font-size: 1rem;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    margin: auto;
    width: 100%;
    max-width: 600px;
    padding: 1em;
    margin: 0;
    background: var(--clr-neutral-100);
    border-top: none;
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    z-index: 3;
    max-height: calc(100vh - 60px);
    overflow: auto;

    @include tablet() {
      width: 100vw;
      margin-top: 0;
      position: fixed;
      left: 50%;
      top:0px;
      transform: translate(-50%, 0%);
      -webkit-box-shadow: none;
      box-shadow: none;
      border-radius: 0 0 var(--border-radius-md) var(--border-radius-md);
      border: 0px;
      // border-bottom:3px solid var(--clr-primary-300);
    }
    @include mobile {
      max-width:unset;
    }
  }
  &__header {
    width: 100%;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__close {
    background: none;
    border: none;
    svg {
      color: var(--clr-neutral-850);
    }
    span {
      display: none;
    }
    &:hover {
      cursor: pointer;
      color: var(--clr-danger);
    }
  }

  .tabs {
    display: flex;
    width: 100%;
  }

  .tab {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    align-items: center;
    justify-content: center;
    padding: 0.8em;
    border: 1px solid transparent;
    color: var(--clr-neutral-800);
    border: var(--locationpopup-tabs-border);
    // opacity:0.5;
    svg {
      max-height: 50px;
    }
    &__content {
      display: none;
      width: 100%;
    }
    &:first-of-type {
      border-radius: var(--border-radius) 0px 0px var(--border-radius);
    }
    &:nth-of-type(2) {
      border-radius: 0px var(--border-radius) var(--border-radius) 0px;
    }
    @include mobile() {
      padding: 5px;
    }
  }

  .active {
    background: var(--clr-neutral-100);
    border: 1px solid var(--clr-primary-100);
    opacity: 1;
    &__content {
      display: block;
    }
    span {
      font-weight: 500;
    }
  }

  //delivery tab
  .delivery_locations {
    display: flex;
    flex-direction: column;
    gap: 1em;
    a {
      text-align: center;
      width: fit-content;
    }
    input {
      padding: 0.8em;
      border: var(--locationpopup-tabs-border);
    }
    .delivery_input {
      display: flex;
      flex-direction: column;
      gap: 0.3em;
      a {
        align-self: flex-end;
      }
    }
  }

  // pickup tab
  .pickup_location {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin: 0em auto 1em;
    border: 1px solid var(--clr-neutral-300);
    border-radius: var(--border-radius);
    padding: 1rem;
    label {
      display: flex;
      background: var(--clr-neutral-100);
      padding: 1em;
      gap: 1em;
      border: var(--locationpopup-tabs-border);
      &:hover {
        cursor: pointer;
      }
    }
    button {
      width: fit-content;
    }
  }
}

.store {
  width: 100%;
  position: relative;
  .image {
    object-fit: cover;
    width: 75px;
    height: 75px;
    position: relative;
    &:hover img {
      filter: brightness(0.4);
    }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size:2rem;
      pointer-events: none;
      transform: translate(-50%, -50%);
      color: white;
    }
  }
}

.selected {
  pointer-events: none;
  background: var(--clr-neutral-600) !important;
  border-color: var(--clr-neutral-300) !important;
  &:hover {
    cursor:not-allowed;
  }
}

.type {
  opacity: 0.8;
  font-weight: var(--fw-regular);
}

.address {
  font-weight: var(--fw-semi-bold);
  opacity: 1;
}

.pickup_locations_address {
  display: flex;
  flex-direction: column;
}

.backdrop {
  // opacity: 0;
  pointer-events: none;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 60px;
  right: 0;
  left: 0;
  // transition: opacity 0.2s;
  z-index: 500;
  pointer-events: all;
  backdrop-filter: blur(5px);
  // transition: backdrop-filter 0s, opacity 0.3s;
  @include tablet() {
    top: 0px;
  }
}

.popupOpen {
  // transition: backdrop-filter 0s, opacity 0.3s;
  .popup {
    opacity: 1;
    pointer-events: all;
  }
  .backdrop {
    opacity: 1;
    pointer-events: all;
    // transition: backdrop-filter 0s, opacity 0.3s;
  }
}
.popupClosed {
  .popup {
    opacity: 0;
    pointer-events: none;
  }
  .backdrop {
    opacity: 0;
    pointer-events: none;
    // transition: backdrop-filter 0s, opacity 0.3s;
  }
}

.storeHeader,
.deliveryHours {
  display: flex;
  flex: 1;
  min-width: 150px;
  gap: 8px;
  margin-bottom: 0.5rem;
  > svg {
    padding-top: 5px;
  }
  > div {
    display: flex;
    flex-direction: column;
  }
  .address {
    display: flex;
    flex: 1;
    gap: 8px;
  }
}
.storeHeader {
  flex-basis: min-content;
  align-items: center;
  position: relative;
}

.storeInfo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
.location {
  display: flex;
  gap: 5px;
  svg {
    padding-top: 2px;
  }
}
.openEveryday {
  color: var(--clr-neutral-700);
  line-height: 16px;
  padding-bottom: 5px;
  text-align: right;
  font-size: 0.875rem;
}

.new:before {
  content: "NEW";
  font-size: 0.625rem;
  background: var(--clr-accent-300);
  color: var(--clr-neutral-100);
  padding: 2px 6px;
  border-radius: 3px;
}
.new {
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 0.75rem;
  color: var(--clr-accent-300);
  border-radius: 3px;
}

.skeleton {
  > div:first-of-type {
    display: flex;
    flex: 1;
    > div:first-of-type {
      width: 75px !important;
      height: 75px !important;
    }
    > div:last-of-type {
      width: 50%;
    }
  }
  > div {
    column-gap: 1rem;
  }
  > div:last-of-type {
    flex-direction: column;
    width: 100%;
    > div:last-of-type {
      width: 100%;
    }
  }
  gap: 0.75rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.storeTitle {
  font-weight:500;
  @include mobile(){
    font-size: 15px;
  }
}
