@import "styles/mixins";

.location {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  button[type="submit"] {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.search {
  display: flex;
  justify-content: space-between;
  @include mobile() {
    flex-direction: column;
  }

  label {
    position: relative;
  }
  input {
    width: 100%;
  }
  input[type="text"] {
    padding: 0.5rem 1rem;
    margin: 0px;
    border-radius:50px 0px 0px 50px;
    @include mobile() {
      border-radius:50px;
    }
  }
}

.input {
  display: flex;
  width: 100%;
}

.findMyLocation {
  font-size: var(--fs-400);
  align-self: flex-start;
  background: none;
  border: none;
  height: 100%;
  width: fit-content;
  padding-bottom:5px;
  &:hover {
    text-decoration: underline;
    color: var(--clr-accent-300);
    cursor: pointer;
    svg {
      color: var(--clr-accent-300);
    }
  }
  svg {
    color: var(--clr-primary-300);
    padding-right:3px;
  }
}

.errorMessage {
  color: var(--clr-danger);
}

.clearInput {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  margin: 0.8em;
  color: var(--clr-neutral-700);
  :hover {
    color: var(--clr-danger);
  }
}

.submitButton {
  input {
    border-radius: 0 50px 50px 0;
    border: 0px !important;
    height: 100%;
    width: 100%;
    padding-inline:1.5rem!important;
    @include mobile() {
      border-radius:50px;
      margin-top:0.5rem;
    }
  }
}

//

.deliveryDetails {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}

.storeInfo,
.deliveryHours {
  flex: 1;
  min-width: 150px;
  width: 100%;
  gap:8px;
  > svg {
    padding-top:5px;
  }
}
.storeInfo {
  flex-basis: min-content;
}

.address {
  display:flex;
  gap:6px;
  svg {
    padding-top:5px;
  }
}

.iconSpacing {
  padding-right: 5px;
}

.hours {
  div {
    line-height: 16px;
    padding: 5px 0px 15px 0px;
  }

  .link {
    text-align: right;
    font-size: 0.875rem;

    .iconSpacing {
      padding-left: 5px;
    }
  }
}

.shopDeliveryButton {
  display: block;
  width: 100%;
}

.openEveryday {
  color: var(--clr-neutral-700);
  line-height: 16px;
  padding-bottom: 5px;
  text-align: right;
  font-size: 0.875rem
}
.successContainer {
  display: none;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: space-between;
  background: var(--clr-neutral-150);
  padding: 1rem;
  border-radius: var(--border-radius);
  border: var(--locationpopup-tabs-border);
}