@import "styles/mixins";

.wrapper {
  // position: sticky;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 500;
}

.navbar {
  background-color: var(--clr-neutral-100);
  position: absolute;
  width: 100%;
  top: 0px;
  transition: top 0.3s;
  // border-bottom: 1px solid var(--clr-neutral-250);
  -webkit-box-shadow: 0 0px 10px 5px rgba(0, 0, 0, 0.02);
  box-shadow: 0 0px 10px 5px rgba(0, 0, 0, 0.02);
  ul {
    list-style-type: none;
  }
  .nav {
    // position: relative;
    z-index: 1;
    height: var(--nav-size);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    // border-bottom:1px solid var(--clr-neutral-250); place navs in separate containers for this to work properly 
  }
}

.visible {
  opacity: 1;
  pointer-events: all;
}
.hidden {
  opacity: 0;
  pointer-events: none;
}

.navlist {
  display: flex;
  gap: 1.5rem;
  padding-left: 1.4rem;
  font-weight: var(--fw-semi-bold);
  color: var(--clr-neutral-800);
  pointer-events: none;
  height: 100%;
  align-items: center;
  a {
    text-transform: capitalize;
    padding-bottom: 2px;
  }
  a:hover {
    border-bottom: 2px solid var(--clr-primary-300);
    opacity: 1;
  }
  .active {
    color: var(--clr-primary-300);
    // border-bottom: 2px solid var(--clr-primary-300);
  }
  @include smallScreen() {
    display: none;
    flex: unset;
  }
  //opacity on hover
  &:hover > * {
    opacity: 0.5;
    transition: 300ms opacity;
  }
  &:hover > *:hover {
    opacity: 1;
  }
  > * {
    pointer-events: auto;
    transition: 300ms opacity;
  }

  .dropdown_icon {
    font-size: 0.8rem;
    padding-left: 0.5rem;
    &:hover {
      cursor: pointer;
      color: var(--clr-primary-300);
    }
  }
  .rotate {
    transform: rotate(180deg);
    padding-right: 0.5rem;
    padding-left: 0rem;
  }
}

.icon {
  font-size: var(--fs-700);
  color: var(--clr-neutral-800);
  width: 20px;
  &:hover {
    // color: var(--clr-primary-300);
    cursor: pointer;
  }
}

.iconlist {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.25rem;
  height: 100%;
  margin-left: auto;
}

.logo {
  margin-right:auto;
  max-width: 180px;
  width: 100%;
}

.navlist .megamenu {
  background: var(--clr-neutral-100);
  // color: var(--clr-neutral-100);
  border-bottom: 2px solid var(--clr-primary-300);
  position: absolute;
  left: 0;
  top: 60px;
  width: 100%;
  padding: 1em 2em 2em 2em;
  &__container {
    display: flex;
    justify-content: space-between;
    max-width: 900px;
    margin: auto;
    grid-gap: 1em;
    gap: 1em;
  }
  &__item {
    text-transform: capitalize;
  }
  ul:before {
    content: attr(aria-label);
    font-weight: 600;
  }
  li {
    margin-bottom: 0.5em;
    font-weight: var(--fw-regular);
    &:first-of-type {
      padding-top: 1em;
    }
  }
  a {
    opacity: 1 !important;
    font-size: 1rem;
    color: var(--clr-neutral-800);
    &:hover {
      border-bottom: none !important;
      text-decoration: underline;
    }
  }
  // buffer for dropdown hover
  &:before {
    // background:red;
    content: "";
    display: block;
    height: 36px;
    position: absolute;
    top: -25px;
    left: 0;
    right: 0;
    width: 1000px;
    margin: auto;
    @include tablet() {
      display: none;
    }
  }
}

// .dropdown {
//   background: white;
//   position: absolute;
//   padding-top: 52px;
//   padding: 1em;
//   margin-left: -16px;
//   min-width: 150px;
//   box-shadow: var(--item-box-shadow);
// }

//#region MenuModal

.modalMenu {
  @include tablet() {
    height: 100%;
  }
  &__modal {
    pointer-events: none;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    top: 0px;
    width: 100%;
    height: 0%;
    background: var(--clr-neutral-100);
    z-index: 2;
    overflow-y: auto;
    &__active {
      pointer-events: all;
      height: 100vh;
    }
    @include mobile() {
      transition: opacity 0.4s, visibility 0.4s, height 0.3s;
    }
  }

  &__close {
    opacity: 1;
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    top: 5px;
    right: 0px;
    display: flex;
    justify-content: flex-end;
    z-index: 2;
    font-size: 2rem;
    color: var(--clr-neutral-900);
    &:hover {
      cursor: pointer;
    }
    @include smallScreen() {
      font-size: 1.25rem;
      opacity: 1;
    }
    @include tablet() {
      top: 0px;
      right: 0px;
    }
  }
}

.modalMobileMenu {
  .modalMenu {
    height: 100%;
    display: flex;
    align-items: center;
  
    &__modal {
      opacity:1;
      pointer-events: none;
      position: absolute;
      margin: auto;
      left: -380px;
      top: 0px;
      width: 100%;
      height: 100vh;
      max-width: 380px;
      overflow-y: auto;
      transition: all .2s;
      &__active {
        left:0px;
        pointer-events: all;
        transition: all .2s;
        @include mobile() {
          max-width: 100%;
        }
      }
    }
  }
  @include smallScreen() {
    display: flex;
    align-items: center;
  }
  .modalMenu__content {
    height:100svh;
  }
}

.modalSearchMenu {
  display: flex;
  align-items: center;
  .modalMenu__modal__active {
    opacity:1;
  }

}

.modalLocationMenu:before {
  content: "";
  position: relative;
  height: 100%;
  left: 50%;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: var(--clr-neutral-150) transparent transparent transparent;
}


// .modalBagMenu {
//   .modalMenu {
//     height: 100%;
//     display: flex;
//     align-items: center;
  
//     &__modal {
//       opacity:1;
//       pointer-events: none;
//       position: absolute;
//       margin-left: auto;
//       margin-right: auto;
//       left:auto;
//       right: -420px;
//       top: 0px;
//       width: 100%;
//       height: 100vh;
//       max-width:500px;
//       overflow-y: auto;
//       transition: all 0s;
//       &__active {
//         opacity:1; 
//         left:auto;
//         right:0px;
//         pointer-events: all;
//         transition: all 0s;
//       }
//       @include tablet() {
//         width: 100%;
//       }
//     }
//   }
//   @include smallScreen() {
//     display: flex;
//     align-items: center;
//   }
//   .modalMenu__content {
//     height:100svh;
//   }
// }

.janeCart {
  .modalMenu {
    height: 100%;
    display: flex;
    align-items: center;
  
    &__modal {
      opacity: 1;
      pointer-events: none;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: auto;
      right: -100%;
      top: 0px;
      width: 500px;
      height: 100vh;
      overflow-y: hidden;
      transition: all 0.2s;
      background: transparent;

      &__active {
        opacity: 1; 
        left: auto;
        right: 0px;
        width:500px;
        pointer-events: all;
        transition: all 0.2s;
      }
      @include tablet() {
        width: 100%;
      }
    }
  }

  @include smallScreen() {
    display: flex;
    align-items: center;
  }

  .modalMenu__content {
    height: 100svh;
  }
}
//#endregion

//#region bag

.bagQuantity {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--clr-secondary-300);
  color: white;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(-3px, -3px);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 10px;
  line-height: 0px;
  pointer-events:none;
}

//#endregion

//#region backdrop

//todo make this global
.backdrop {
  pointer-events: none;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  right: 0;
  left: 0;
  opacity: 0;
  backdrop-filter: blur(0px);
  z-index: 1;
  transition:top 0.3s, backdrop-filter 0s, opacity 0.3s;
  @include mobile(){
    transition:top 0s, backdrop-filter 0s, opacity 0.3s;
  }
}

.backdrop_active {
  pointer-events: all;
  opacity: 1;
  backdrop-filter: blur(5px);
  @include mobile(){
    transition:top 0.3s, backdrop-filter 0s, opacity 0.3s;
  }
}

.locationSelect {
  @include mobile() {
    display: none;
  }
}

//#endregion
