.logo {
  margin-right:auto;
  max-width: 180px;
  width: 100%;
}

.mobileMenu {
  display:flex;
  flex-direction: column;
  gap:1rem;
  height:100%;
  padding-inline:1rem;
}

.header {
  position:sticky;
  top:0px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  gap:2rem;
  padding:1rem 0rem;
  background-color: white;
}

.list {
  display: flex;
  flex-direction: column;
  gap:0.5rem;
  width: 100%;
  height:100%;
  // padding-bottom:100px;
  color:var(--clr-neutral-800);
  font-weight:600;
}

.item:last-of-type {
  padding-bottom:1rem;
}

.item  {
  font-size:1.75rem;
  text-transform: capitalize;
  &:hover {
    color:var(--clr-primary-300);
  }
}
.toggle  {
  // font-size:1.25rem;
  list-style-type: none;
  text-transform: capitalize;
  padding:5px;
}

// toggle content

.toggle {
  border-bottom:none!important;
  padding:0px;
  a {
    font-weight:400;
    &:hover {
      color:var(--clr-primary-300);
    }
  
  }
}

.toggle div:first-of-type { // toggle title
  align-items: center;
  gap:0.5rem;
  padding:0px;
  span {
    font-size:1.75rem;
  }
  span:nth-of-type(2) {
    margin:0;
    font-size:0.875rem;
  }
  &:hover {
    color:var(--clr-primary-300);
  }
}

.toggle div:nth-of-type(2) { // toggle body
  display:flex;
  flex-direction: column;
  margin-left:0.5rem;
  a:first-of-type {
    margin-top:0.5rem;
  }
  a:last-of-type {
    margin-bottom:1rem;
  }
  a {
    padding:5px 0rem;
    font-size: 1.25rem;
  }
}

.footer {
  width:100%;
  position:fixed;
  bottom:0px;
  left:0px;
  ul {
    background:var(--clr-neutral-150);
    padding:1rem 0rem;
    margin-top:0rem;
  }
  &:before {
    border-bottom:1px solid var(--clr-neutral-250);
    content:'';
    display:block;
    width:100%;
    height:60px;
    background:linear-gradient(0deg, rgba(255,255,255,0.9), transparent);
  }
}

.socials {
  display:flex;
}


.subList { // toggle title
  padding-top:10px;
  li {
   padding-bottom:10px;
  }
  span {
    font-size:1.25rem;
  }
  
}

.secondary div:first-of-type span { // toggle title
  font-size:1.25rem;
}

.secondary:nth-of-type(2) {
  padding-top:1rem;
  position:relative;
}
.secondary:nth-of-type(2):before { 
  content: '';
  display:block;
  width: 100%;
  height: 1px; 
  background: rgb(212, 212, 212);
  top: 0;
  left: 0;
  margin-bottom:1.75rem;
}