@import "styles/mixins";


$siteW: 1080px;

:root, [data-amplify-theme] {
  --nav-size: 60px;
  @include tablet() {
    --nav-size: 60px;
  }
  
  --clr-indica:#31318e;
  --clr-indica-dominant:#4d8cc7 ;
  --clr-hybrid:#2D7B5C;
  --clr-sativa-dominant:#ce5d91 ;
  --clr-sativa:#e9b50a ;
  --clr-cbd:#c24239 ;

  --clr-neutral-100: rgb(255, 255, 255);
  --clr-neutral-125: rgb(251, 251, 251);
  --clr-neutral-150: rgb(247, 247, 247);
  --clr-neutral-175: rgb(242, 242, 242);
  --clr-neutral-200: rgb(238, 238, 238);
  --clr-neutral-250: rgb(232, 232, 232);
  --clr-neutral-275: rgb(225, 225, 225);
  --clr-neutral-300: rgb(204, 204, 204);
  --clr-neutral-400: rgb(179, 179, 179);
  --clr-neutral-500: rgb(155, 155, 155);
  --clr-neutral-600: hsl(0, 0%, 50%);
  --clr-neutral-650: hsl(0, 0%, 45%);
  --clr-neutral-700: hsl(0, 0%, 40%);
  --clr-neutral-750: hsl(0, 0%, 35%);
  --clr-neutral-800: hsl(0, 0%, 25%);
  --clr-neutral-850: hsl(240, 6%, 10%);
  // --clr-neutral-900: hsl(0, 0%, 10%);
  --clr-neutral-900: hsl(240, 4%, 6%);

  --clr-primary-25: rgb(245, 239, 252);
  --clr-primary-50: rgb(199, 176, 227);
  --clr-primary-100: hsl(267, 34%, 68%);
  --clr-primary-200: hsl(267, 43%, 60%);
  --clr-primary-300: hsl(267, 43%, 42%); //default
  --clr-primary-400: hsl(267, 46%, 32%); 
  --clr-primary-600: hsl(267, 43%, 20%);
  --clr-primary-900: hsl(267, 43%, 14%);

  --clr-secondary-100:#53e168;
  --clr-secondary-300:#379D47;

  // --clr-accent-300:#c155b8; not a11y w small white text
  --clr-accent-300:#B342A9;

  --clr-danger: hsl(0, 49%, 58%);
  --clr-warning: #ffe484;
  --clr-specials: #e6d0f9;
  --clr-success: #267f33;


  // --toastify-color-success:var(--clr-secondary-100)!important;
  
  --toastify-color-progress-light: linear-gradient(
    to right,
    var(--clr-accent-300),
    var(--clr-accent-300)
  )!important;

  
// try this as grey/purp background #f3f0f7

  // --ff-primary: "BentonSans-Regular", serif;

  // --ff-body: var(--ff-primary);
  // --ff-title: var(--ff-primary);

  --fw-thin: 400;
  --fw-regular: 500;
  --fw-semi-bold: 600;
  --fw-bold: 700;

  --fs-100: 0.625rem;
  --fs-200: 0.75rem;
  --fs-250: 0.8125rem;
  --fs-300: 0.875rem;
  --fs-400: 1rem;
  --fs-500: 1.125rem;
  --fs-600: 1.375rem;
  --fs-700: 1.5rem;
  --fs-800: 1.625rem;
  --fs-900: 1.75rem;
  --fs-1000: 2rem;
  --fs-1100: 2.25rem;
  --fs-1200: 2.5rem;
  --fs-1300: 2.75rem;

  --fs-body: var(--fs-400);
  --fs-title: var(--fs-900);
  --fs-sub-title: var(--fs-800);
  --fs-nav: var(--fs-500);
  --fs-button: var(--fs-400);

  --border:1px solid var(--clr-neutral-250);

  --border-radius-sm: 3px;

  --border-radius: 16px;

  --border-radius-md: 10px;

  --locationpopup-tabs-border: 1px solid hsl(0, 0%, 90%);

  --item-box-shadow: 0 0 0 1px rgb(35 38 59 / 5%), 0 1px 3px 0 rgb(35 38 59 / 10%);

  // --box-shadow:0 0px 10px rgba(0,0,0,.12);
  --box-shadow:0px 2px 8px rgba(0, 0, 0, 0.05);
  --box-shadow-light:0 0px 15px rgba(0,0,0,.12);

  // these should be hooks...
  --nav-buffer:61px;
  --footer-buffer:359px;


  --amplify-colors-font-primary: red;
  /* you can also use references: */
  --amplify-internal-button-background-color: red!important;

  @include tablet(){
    --nav-buffer:61px;
    --footer-buffer: 383px;
  }

}
