@import "styles/mixins";

.product {
  position: relative;
  background: var(--clr-neutral-100);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  border-radius: var(--border-radius);
  border: 1px solid var(--clr-neutral-250);
  transition: box-shadow 0.2s ease;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 8px;
  &:hover {
    // border: 1px solid transparent;
    box-shadow: var(--box-shadow);
  }
  @include tablet() {
    &:hover {
      box-shadow: none;
      border: 1px solid var(--clr-neutral-250);
    }
  }

  .body {
    padding: 1rem 1rem 0rem 1rem;
    width: 100%;
    display: grid;
    gap: 9px;
    align-content: center;
    // padding: 0rem 1rem 1rem;

    .name {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.2rem;
      // -webkit-line-clamp: 1;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      // -webkit-box-orient: vertical;
      // overflow: hidden;
      max-width: 100%;
      &:hover {
        text-decoration: underline;
        text-underline-offset: 3px;
        color:var(--clr-accent-300)
      }
      @include mobile() {
        font-size: 0.875em;
      }
    }

    .kind {
      background: var(--clr-neutral-700);
      color: var(--clr-neutral-100);
      padding: 0px 8px;
      border-radius: var(--border-radius-sm);
      height: 18px;
    }

    @include mobile(){
      padding: 1rem 0.5rem 0rem 0.5rem;
    }
  }

  .image_container {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    // height: 230px;
    height: 250px;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    img {
      object-fit: cover;
      // object-fit: contain;
      border-radius: var(--border-radius) var(--border-radius) 0 0;
      // padding:1rem 1rem 0rem 1rem;
      @include mobile(){
        // padding:0.5rem;
      }
    }
    @include mobile(){
      height:200px;
    }
  }

  .product-type {
    display: flex;
    flex-direction: row;
    gap: 1em;
  }
}

.saleTag {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
}

.description {
  display:none;
}

// list style
.list {
  padding-inline:1rem;
  @include tablet(){
    padding-inline:0rem;
  }
  > div > ol {
    display: flex;
    flex-direction: column;
    margin-top:1rem;
    gap:0.5rem;
  }
  .product {
    display:flex;
    flex-direction: row;
    align-items: center;
    gap:1rem;
    padding:1rem;
    > div {
      margin-top:0px;
      width: fit-content;
    }
    > div:last-of-type {
      display:flex;
      justify-content: flex-end;
      margin-left:auto;
      column-gap: 1rem;
      row-gap:0.5rem;
      text-wrap: nowrap;
      flex-wrap:wrap;

      div {
        padding:0px;
        display:flex;
        width:fit-content!important;
      }
      button {
        border-radius: var(--border-radius-md);
        padding-inline:1rem;
      }

    }
    > a {
      min-width:100px;
      @include smallMobile(){
        min-width:60px;
      }
    }
    .saleTag {
      position: unset;
      background-color: transparent;
      color: var(--clr-primary-300);
      font-weight: bold;
      padding: 0px;
      font-size: 0.75rem;
      display: flex;
      align-items: center;
      svg {
        display:block;
        padding-right:5px;
        font-size:1rem;
      }
    }
    .product__info {
      flex-wrap: wrap;
    }
  }
  .add_to_bag{
    margin-top:0;
  }
  .body {
    // flex-basis: 40%;
    flex-wrap:wrap;
    padding:0px;
    width:fit-content;
  }
  .image_container {
    height: 150px;
    width: 150px;
    margin: 0;
    border-radius: var(--border-radius-md);
    img {
      border-radius: var(--border-radius-md);
      object-fit: contain !important;
    }
  }
  .description {
    display:flex;
    max-width:500px;
    @include smallScreen(){
      display:none;
    }
  }

  @include mobile() {
    padding-inline:0.5rem;
    .product {
      display: grid;
      grid-template-columns: auto 1.5fr;
      grid-template-areas:
        "image body"
        "image button";
      gap: 1rem;
      row-gap: 0.5rem;
      border: none;
      border-bottom: 1px solid var(--clr-neutral-250);
      border-radius: 0;
      padding: 1rem 0.5em;

    }
    .options {
      grid-area: button;
      margin: 0;
      padding: 0;
      > * {
        justify-content: flex-start;
      }
      span {
        margin: unset;
      }
    }
    .body {
      grid-area: body;
      width: 100%;
      text-align: left;
      // display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
    }
    .image_container {
      grid-area: image;
      grid-row: span 2;
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 0;
      border-radius: var(--border-radius-md);
      img {
        border-radius: var(--border-radius-md);
        object-fit: contain !important;
      }
    }
    .buttonContainer {
      justify-content: flex-start;
      margin-top: 0.5rem;
    }
    .add_to_bag {
      height: unset;
      width: unset;
      background: transparent;
      border: none;
      font-weight: 500;
      padding: 0;
      &:before {
        content: "+";
        color: var(--clr-primary-300);
        font-weight: 500;
      }
    }
    .modify_cart {
      height: unset;
      padding: 0;
    }
    // .name {
    //   font-size: 0.9rem;
    //   white-space: unset;
    // }
    .saleTag {
      position: unset;
      background-color: transparent;
      color: var(--clr-primary-300);
      font-weight: bold;
      padding: 0px;
      font-size: 0.75rem;
      display: flex;
      align-items: center;
      svg {
        display: inline-block;
        padding-right: 5px;
        font-size: 1rem;
      }
    }
  }
}

// new styling

.product__info {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.75rem;
  text-transform: capitalize;
  width: 100%;
  font-weight: 500;
  overflow: hidden;
  @include mobile() {
    font-size: 0.688rem;
    flex-wrap: wrap;
  }
}

.subtype {
  color: var(--clr-neutral-700);
  border-radius: var(--border-radius-sm);
  font-size: 0.75rem;
  font-weight: normal;
  background: var(--clr-neutral-250);
  padding: 0px 5px;
}

.priceAndBag {
  // background:red;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dosages {
  display: flex;
  flex-wrap:wrap;
  column-gap: 0.5rem;
}

.toast {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  row-gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
}


// .overlay {
//   background:rgba(0, 0, 0, 0.03);
//   position:absolute;
//   top:0;
//   left:0;
//   width:100%;
//   height:100%;
//   border-radius:var(--border-radius) var(--border-radius) 0 0;
// }