.strain {
  position: relative;
  display: inline-block;
  padding: 1px 6px;
  border-radius: 50px;
  text-decoration: none;
  overflow: hidden;
  // transition: background-color 0.3s ease;
  white-space: nowrap; /* Prevent wrapping of strain name */
  width: 100%;
  max-width: fit-content;
  text-transform: capitalize;
  // font-weight:500;
  &:hover {
    .fullStrainName {
      left: calc(100% + 6px); /* Include padding in the animation */
      // transition: left 0.3s ease;
    }
  }
  svg {
    font-size: 1rem;
  }
  .fullStrainName {
    position: absolute;
    top: 0;
    left: calc(100% + 6px); /* Initially position full strain name off the screen to the right */
    opacity: 0;
    // transition: left 0.3s ease;
  }
}
