@import "styles/mixins";

.social_links {
  display: flex;
  align-items: center;
  margin-top: 1em;
  a {
    font-size: 1.5rem;
    width: 100%;
    text-align: center;
    padding: 0.3em 0.5em;
    color: var(--clr-primary-300);
    border-radius:5px;
    width:44px;
    height:44px;
    &:hover {
      color: var(--clr-neutral-100);
    }
  }
  @include smallScreen(){
    justify-content: center;
  }
}

.instagram:hover {
  background: radial-gradient(
      circle farthest-corner at 35% 90%,
      #fec564,
      transparent 50%
    ),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(
      #6559ca,
      #bc318f 30%,
      #e33f5f 50%,
      #f77638 70%,
      #fec66d 100%
    );
}
.facebook:hover {
  background-color: #4267b2;
}
.youtube:hover {
  background-color: #ff0000;
}
.twitter:hover {
  background-color: #1da1f2;
}